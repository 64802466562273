<template>
  <div class="document-upload">
    <Header v-bind:type="type" />
    <Breadcrumb_business />
    <MainId />
    <div class="ll-body container" role="main">

      <table v-if="debug" class="dump">
        <tr>
          <td>errCnt:</td>
          <td>{{ errCnt }}</td>
        </tr>
      </table>

      <form v-on:submit.prevent>
        <section class="ll-section">
          <div class="form-title">Connect to Banker</div>

          <h1 class="section-title">Document Uploads</h1>
          <div class="section-description"><strong>Optional:</strong> Please submit your recent financial documentation to help us gain a better understanding of your business</div>
          <div class="v-spacer-30" />

          <div id="commercial-page-errors" />

          <div v-if="errCnt" class="page-error-container" data-test="page-err">
            <div class="page-error-icon-container">
              <img class="page-error-icon"
                srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                src="@/assets/images/exclaimation-lg.png" alt="" />
            </div>
            <div class="page-error-msg">
              You must complete or correct all required fields to proceed
            </div>
          </div>
          <div v-if="errCnt" class="v-spacer-30" />

          <div id="document-upload"
            :class="errorAppFileValidation ? 'form-upload-container form-upload-container-error' : 'form-upload-container'">
            <div class="ll-row row1-container">
              <!-- Upload Documents -->
              <div class="tf-label upload-docs-note" v-if="this.business_company_structure == 'sole'"><p class="upload-docs-note">Sole Proprietors Personal Financial Statement. Personal Tax Returns - Last two years' Federal tax returns, including all schedules.</p></div>
              <div class="tf-label" v-else><p class="upload-docs-note">Corporations, Partnerships, Limited Liability Companies Business Financial Records - Last two years' Federal tax returns, including all schedules and an interim statement.</p> <p class="upload-docs-note">Personal Tax Returns - Last two years' Federal tax returns, including all schedules, of the owner(s), general partner(s), and/or principal officer(s).</p></div>
              <div class="tf-label-normal"> Maximum file size: 5mb. Accepted file types: PDF, JGP, GIF, PNG, ZIP, DOCX, DOC, etc. PDF is preferred. </div>
            </div>
            <div class="v-spacer-30" />

            <div v-if="this.document_upload_setup">
              <div v-for="(url, index) in this.document_upload_setup" :key="index">
                <div class="ll-row">
                  <div v-if="!this.uploaded_documents || !this.uploaded_documents[index]">

                    <div class="dd-area" @dragenter="highlight($event)" @dragover="highlight($event)"
                      @dragleave="unhighlight($event)" @drop="unhighlight($event), handleDrop($event, index, url)">
                      <img class="dd-icon clip" srcset="@/assets/images/clip.png 1x, @/assets/images/clip-2x.png 2x"
                        src="@/assets/images/clip-2x.png" alt="" />
                      <div class="dd-txt">
                        <input hidden :id="'app_upload' + '_' + index" type="file"
                          @change="validateFile($event, index, url)"
                          accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png, image/jpeg, image/tiff, image/gif, application/zip"
                          multiple="" data-test="cb-document-upload-1" />
                        <button type="button" class="upload_link" @click="triggerFileInput($event, index)">Choose file</button> or drop file here
                      </div>
                    </div>
                  </div>

                  <div v-else>
                    <div
                      :class="this.uploaded_documents[index].valid || (this.uploaded_documents[index].uploadAttempt && !this.uploaded_documents[index].uploadFailed) ? 'dd-upload-area' : 'dd-upload-area-error'">
                      <div class="upload-area">
                        <img class="dd-upload-icon success" v-if="this.uploaded_documents[index].valid"
                          srcset="@/assets/images/green_check.png 1x, @/assets/images/green_check_2x.png 2x"
                          src="@/assets/images/green_check_2x.png" alt="" height="24" />
                        <img class="dd-upload-icon load"
                          v-else-if="this.uploaded_documents[index].uploadAttempt && !this.uploaded_documents[index].uploadFailed"
                          srcset="@/assets/images/progress_circle.png 1x, @/assets/images/progress_circle_2x.png 2x"
                          src="@/assets/images/progress_circle_2x.png" alt="" height="24" />
                        <img class="dd-upload-icon fail" v-else
                          srcset="@/assets/images/warning-yellow-circle.png 1x, @/assets/images/warning-yellow-circle-2x.png 2x"
                          src="@/assets/images/warning-yellow-circle-2x.png" alt="" height="24" />

                        <div class="dd-r-txt">
                          {{ this.formatFileName(this.uploaded_documents[index].file.name) }}
                          <span class="dd-reason">{{ this.uploaded_documents[index].reason }} </span>
                        </div>
                      </div>
                      <div class="button-area">
                        <button type="button" class="dd-button-success" v-if="this.uploaded_documents[index].valid"
                          @click="triggerFileReset(index)" data-test="cb-delete-document">
                          <img class="dd-icon trash"
                            srcset="@/assets/images/trash-gray.png 1x, @/assets/images/trash-gray-2x.png 2x"
                            src="@/assets/images/trash-gray-2x.png" alt="" height="12" />
                          Delete
                        </button>
                        <button type="button" class="dd-button"
                          v-else-if="this.uploaded_documents[index].uploadAttempt && !this.uploaded_documents[index].uploadFailed"
                          @click="triggerFileReset(index)" data-test="cb-cancel-document">
                          <img class="dd-icon close"
                            srcset="@/assets/images/close.png 1x, @/assets/images/close_2x.png 2x"
                            src="@/assets/images/close_2x.png" alt="" height="12" />
                          Cancel
                        </button>
                        <button type="button" class="dd-button dd-button-clear" v-else @click="triggerFileReset(index)"
                          data-test="cb-clear-document">
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="v-spacer-30" />
              </div>
              <div v-if="errorAppFileValidation" class="form-error-msg" data-test="cb-document-error">
                <img class="form-error-icon"
                  srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                  src="@/assets/images/exclaimation-lg-2x.png" alt="" />
                <div role="alert" class="form-error-txt">Please upload valid files, or skip this step for now.</div>
              </div>
            </div>
            <!-- <div v-if="errorAppFileValidation">
              <div class="callout">If you have trouble uploading your documents, you can select the "<a
                  href="#financial-label-app-upload-timing">Share Later</a>" option above and continue. You will be
                contacted by a First Hawaiian Bank representative to provide proof of income verification at a later
                time.</div>
            </div> -->
          </div>


          <div class="v-spacer-30" />
          <div class="v-spacer-30" />

          <div class="ll-row">
            <!-- Additional Documents -->
            <div class="tf-label"> Additional documents </div>
            <div>
              <ul>
                <li>If Commercial Real Estate Related: Three years of business tax returns or Profit and Loss Statement</li>
                <li>If loan is for a retail, industrial, office property: Leases</li>
                <li>If loan is for a mixed-use (e.g., with residential property): Sample rental agreement for appraiser
                </li>
                <li>If property is partially rented by third-party tenants: Rent rolls and operating statements</li>
              </ul>
            </div>
          </div>
          <div class="v-spacer-30" />

          <div id="additional-document-upload">
            <div v-if="this.additional_document_upload_setup">
              <div v-for="(url, index) in this.additional_document_upload_setup" :key="index">
                <div class="ll-row">
                  <div v-if="!this.uploaded_additional_documents || !this.uploaded_additional_documents[index]">

                    <div class="dd-area" @dragenter="highlight($event)" @dragover="highlight($event)"
                      @dragleave="unhighlight($event)"
                      @drop="unhighlight($event), handleAdditionalDrop($event, index, url)">
                      <img class="dd-icon clip" srcset="@/assets/images/clip.png 1x, @/assets/images/clip-2x.png 2x"
                        src="@/assets/images/clip-2x.png" alt="" />
                      <div class="dd-txt">
                        <input hidden :id="'additional_app_upload' + '_' + index" type="file"
                          @change="validateAdditionalFile($event, index, url)" data-test="cb-additional-document"
                          accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/png, image/jpeg, image/tiff, image/gif, application/zip"
                          multiple="" />
                        <button type="button" class="upload_link" @click="triggerAdditionalFileInput($event, index)">Choose file</button> or drop file here
                      </div>
                    </div>
                  </div>

                  <div v-else>
                    <div
                      :class="this.uploaded_additional_documents[index].valid || (this.uploaded_additional_documents[index].uploadAttempt && !this.uploaded_additional_documents[index].uploadFailed) ? 'dd-upload-area' : 'dd-upload-area-error'">
                      <div class="upload-area">
                        <img class="dd-upload-icon success" v-if="this.uploaded_additional_documents[index].valid"
                          srcset="@/assets/images/green_check.png 1x, @/assets/images/green_check_2x.png 2x"
                          src="@/assets/images/green_check_2x.png" alt="" height="24" />
                        <img class="dd-upload-icon load"
                          v-else-if="this.uploaded_additional_documents[index].uploadAttempt && !this.uploaded_additional_documents[index].uploadFailed"
                          srcset="@/assets/images/progress_circle.png 1x, @/assets/images/progress_circle_2x.png 2x"
                          src="@/assets/images/progress_circle_2x.png" alt="" height="24" />
                        <img class="dd-upload-icon fail" v-else
                          srcset="@/assets/images/warning-yellow-circle.png 1x, @/assets/images/warning-yellow-circle-2x.png 2x"
                          src="@/assets/images/warning-yellow-circle-2x.png" alt="" height="24" />

                        <div class="dd-r-txt">
                          {{ this.formatFileName(this.uploaded_additional_documents[index].file.name) }}
                          <span class="dd-reason">{{ this.uploaded_additional_documents[index].reason }} </span>
                        </div>
                      </div>
                      <div class="button-area">
                        <button type="button" class="dd-button-success"
                          v-if="this.uploaded_additional_documents[index].valid"
                          data-test="cb-delete-additional-document" @click="triggerAdditionalFileReset(index)">
                          <img class="dd-icon trash"
                            srcset="@/assets/images/trash-gray.png 1x, @/assets/images/trash-gray-2x.png 2x"
                            src="@/assets/images/trash-gray-2x.png" alt="" height="12" />
                          Delete
                        </button>
                        <button type="button" class="dd-button"
                          v-else-if="this.uploaded_additional_documents[index].uploadAttempt && !this.uploaded_additional_documents[index].uploadFailed"
                          data-test="cb-cancel-additional-document" @click="triggerAdditionalFileReset(index)">
                          <img class="dd-icon close"
                            srcset="@/assets/images/close.png 1x, @/assets/images/close_2x.png 2x"
                            src="@/assets/images/close_2x.png" alt="" height="12" />
                          Cancel
                        </button>
                        <button type="button" class="dd-button dd-button-clear" data-test="cb-clear-additional-document"
                          v-else @click="triggerAdditionalFileReset(index)">
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="v-spacer-30" />
                </div>
              </div>
            </div>
            <!-- <div v-if="errorAdditionalFileValidation">
              <div class="callout">If you have trouble uploading your documents, you can select the "<a
                  href="#financial-label-app-upload-timing">Share Later</a>" option above and continue. You will be
                contacted by a First Hawaiian Bank representative to provide proof of income verification at a later
                time.</div>
            </div> -->
          </div>

          <!-- Errors from BE -->
          <div class="sys-err-msg" v-if="systemErrorMsg">
            <div>
              <img class="form-error-icon sys-err-icon"
                srcset="@/assets/images/exclaimation-lg.png 1x, @/assets/images/exclaimation-lg-2x.png 2x"
                src="@/assets/images/exclaimation-lg.png" alt="" />{{ systemErrorMsg }}
            </div>
            <ul v-if="systemErrorArray && systemErrorArray.length > 0" class="sys-err-list">
              <li v-for="item in systemErrorArray" :key="item.index">
                {{ item.error_message }} : {{ item.error_value }}
              </li>
            </ul>
          </div>
          <div class="v-spacer-30" />

          <div class="btn-container">

            <button id="submit-continue-btn" class="submit-btn" type="button" @click="handleSubmit()"
              data-test="submit-btn-submit">
              <span class="submit-txt">Submit Form</span>
            </button>
          </div>

          <div class="v-spacer-60" />

          <div class="btn-container-left">
            <button type="button" class="back-btn" @click="handleBack()" data-test="submit-btn-back">
              <img class="back-icon" srcset="@/assets/images/backIcon.png 1x, @/assets/images/backIcon-2x.png 2x"
                src="@/assets/images/backIcon-2x.png" alt="" />
              <span class="sr-only">Back</span>
            </button>
            <div class="link-container">
              <a class="return-fhb-link" href="http://fhb.com">Return to FHB.com</a>
            </div>
          </div>

          <div class="v-spacer-40" />
        </section>
      </form>
    </div>
    <Footer />
  </div>

</template>

<script>
import Api from '@/app/ll-commercial-api'
import Header from '@/components/Header'
import Validator from '@/app/validation'
import MainId from '@/components/MainId'
import Footer from '@/components/Footer'
import Breadcrumb_business from '@/views/business/connect_banker/Breadcrumb_business.vue'
import jQuery from 'jquery'
import { mapActions, mapGetters } from 'vuex'
import Utils from '@/app/utils'

export default {
  name: 'Document Upload',
  components: {
    Header,
    MainId,
    Footer,
    Breadcrumb_business,

  },
  data() {
    return {
      debug: false,
      systemErrorMsg: '',

      errCnt: 0,
      file_count: 0,
      additional_file_count: 0,

      nonProd: false,
      errorAppFileValidation: false,
      errorAdditionalFileValidation: false,
    }
  },
  created() {
    this.setCurrentPage(3);
    this.nonProd = true
  },
  computed: {
    ...mapGetters("connectToBanker",
      ['currentPage', 'jwt_token', 'type', 'application_id', 'document_upload_setup', 'additional_document_upload_setup', 'uploaded_documents', 'uploaded_additional_documents', 'uploaded_files', 'uploaded_additional_files', 'business_company_structure']
    ),

  },
  mounted() {
    this.setType('commercial-loan')
    jQuery("html, body").animate({ scrollTop: 0 });

    if (this.uploaded_documents && this.uploaded_documents.length > 0) {
      this.file_count = this.uploaded_files.length
    } else {
      this.setUploadedDocumentsInit()
    }

    if (this.uploaded_additional_documents && this.uploaded_additional_documents.length > 0) {
      this.additional_file_count = this.additional_uploaded_files.length
    } else {
      this.setUploadedAdditionalDocumentsInit()
    }

  },
  methods: {
    ...mapActions("connectToBanker",
      ['setCurrentPage', 'setJWTToken', 'setType', 'setUploadedDocuments', 'setApplicationID', 'setValidatedNavPage', 'setUploadedDocumentsInit', 'setUploadedDocumentsReason', 'setUploadedDocumentsUploadAttempt', 'setUploadedDocumentsUploadFailed', 'setUploadedDocumentsUploadValid', 'setUploadedDocumentsReset', 'setUploadedFiles',
        'setUploadedAdditionalDocuments', 'setUploadedAdditionalDocumentsInit', 'setUploadedAdditionalDocumentsReason', 'setUploadedAdditionalDocumentsUploadAttempt', 'setUploadedAdditionalDocumentsUploadFailed', 'setUploadedAdditionalDocumentsUploadValid', 'setUploadedAdditionalDocumentsReset', 'setUploadedAdditionalFiles', 'setSubmissionID']
    ),
    async putBankerPage(payload) {
      this.systemErrorMsg = '';
      this.systemErrorArray = [];

      const resp = await Api.putBankerPage(this.application_id, payload, this.jwt_token);
      console.log('--- response ---');
      console.log(resp);

      if (resp && resp.data) {

        if (resp.status == 200) {
          // update breadcrumb state
          this.setValidatedNavPage({ name: 'document', isValid: 1 });
          this.setSubmissionID(resp.data.response.submission_id ? resp.data.response.submission_id : "")
          // sleep for a smooth transition
          await new Promise(r => setTimeout(r, 400));

          this.$router.push({ name: 'Connect To Banker Submitted' })

          Utils.removeProcessingMask('#submit-continue-btn')
        } else {
          // system error
          if (resp.data.message) {
            this.systemErrorMsg = resp.data.message;
          }

          if (resp.data.response && resp.data.response.application_errors && (resp.data.response.application_errors.length > 0)) {
            for (const x of resp.data.response.applicant_errors) {
              for (const y of x.errors) {
                this.systemErrorArray.push(y)
              }
            }
          }
          Utils.removeProcessingMask('#submit-continue-btn')
        }
      }
    },
    handleBack() {
      this.$router.push({ name: 'Business Information' })
    },

    handleSubmit() {

      Utils.addProcessingMask('#submit-continue-btn')
      this.writeFilenamesToStore();

      this.validatePage();

      this.$nextTick(() => {
        this.errCnt = jQuery('.form-error-txt').length;

        if (!this.errCnt) {
          const payload =
          {
            "page": this.currentPage,
          }

          this.putBankerPage(payload);
        } else {
          this.setValidatedNavPage({ name: 'document', isValid: 0 });

          if (this.errCnt > 1) {
            jQuery("html, body").animate({ scrollTop: jQuery("#commercial-page-errors").offset().top - 20 });
          } else {
            let offset = 0;
            if (this.errorAppFileValidation) offset = jQuery("#document-upload").offset().top

            jQuery("html, body").animate({ scrollTop: offset });
          }
          // remove processing
          Utils.removeProcessingMask('#submit-continue-btn')
        }

      })

    },
    validatePage() {
      this.validateApplicantFileUploads();
    },
    validateApplicantFileUploads() {
      this.refreshFileErrs()
    },
    highlight(e) {
      e.preventDefault()
      e.stopPropagation()
      e.target.classList.add('highlight')
    },
    unhighlight(e) {
      e.preventDefault()
      e.stopPropagation()
      e.target.classList.remove('highlight')
    },
    handleDrop(e, index, url) {
      let dt = e.dataTransfer
      let files = dt.files

      if (files && files.length && files.length > 0) {
        this.validateFileDD(e, index, url, files[0])
      }
    },
    validateFileDD(event, index, url, file) {

      this.setUploadedDocuments({ index: index, url: url, file: file })

      Validator.isValidUploadedFileWithCallback(file, this.validateFileCallback, index);
    },

    validateFile(event, index, url) {
      let files = event.target.files;
      console.log("found files: ", files[0])
      if (files.length == 0) {
        return
      }
      console.log("document url: ", url)
      this.setUploadedDocuments({ index: index, url: url, file: files[0] })
      console.log(" index value: ", index)
      Validator.isValidUploadedFileWithCallback(files[0], this.validateFileCallback, index);
    },
    triggerFileInput(event, index) {
      event.preventDefault();
      jQuery("#app_upload_" + index + ":hidden").trigger('click');
    },
    async validateFileCallback(valid, index, file) {
      console.log("index of file: ", index)
      if (!valid) {
        this.setUploadedDocumentsReason({
          index: index,
          reason: 'could not be uploaded. Only the following extensions are allowed: PDF, JPG, JPEG, ZIP, TIFF, GIF, DOC, and DOCX.'
        })
      }
      let maxFileSize = 5000000
      let maxFileSizeText = '5MB'
      if (this.nonProd) {
        maxFileSize = 1000000
        maxFileSizeText = '1MB'
      }
      if (file.size > maxFileSize) {
        this.setUploadedDocumentsReason({
          index: index,
          reason: 'exceeds  the maximum file size for this site. File sizes cannot be larger than ' + maxFileSizeText + '.'
        })

        valid = false;
      }

      // Attempt upload
      if (valid) {
        this.setUploadedDocumentsUploadAttempt({
          index: index,
          value: true
        })

        this.setUploadedDocumentsReason({
          index: index,
          reason: 'is uploading'
        })
        console.log("uploaded document: ", this.uploaded_documents[index])
        let resp = await Api.uploadFile(this.uploaded_documents[index].url, file)

        // Did it get cancelled?
        if (!this.uploaded_documents[index]) {
          return;
        }

        if (resp.status >= 300) {
          this.setUploadedDocumentsUploadFailed({

            index: index,
            value: true
          })

          this.setUploadedDocumentsReason({

            index: index,
            reason: 'could not be uploaded. There was an error during the upload process'
          })

          valid = false;
        }
      }
      if (valid) {
        this.setUploadedDocumentsReason({

          index: index,
          reason: 'was successfully uploaded'
        })
        this.file_count += 1
      }

      this.setUploadedDocumentsUploadValid({

        index: index,
        value: valid
      })
      console.log("file_count", this.file_count)
      this.refreshFileErrs()
    },
    refreshFileErrs() {
      let appHasErr = false
      for (let m in this.file_count) {
        if (this.uploaded_documents[m] && !this.uploaded_documents[m].valid) {
          appHasErr = true
          break
        }
      }
      this.errorAppFileValidation = appHasErr
    },
    formatByteSize(size) {
      return Utils.formatByteSize(size);
    },
    formatFileName(name) {
      if (name.length > 25) {
        name = name.substring(0, 10) + '...' + name.substring(name.length - 7, name.length)
      }
      return name
    },
    triggerFileReset(index) {
      console.log(this.uploaded_documents)
      if (this.uploaded_documents[index].valid) {
        this.file_count -= 1
        console.log('delete file count', this.file_count)
      }

      Api.deleteFile(this.application_id, index, 'documents', this.jwt_token)
      this.setUploadedDocumentsReset({ index: index })
      //this.refreshFileErrs()
    },

    triggerAdditionalFileReset(index) {
      console.log(this.uploaded_additional_documents)
      if (this.uploaded_additional_documents[index].valid) {
        this.additional_file_count -= 1
        console.log('delete additional file count', this.additional_file_count)
      }

      Api.deleteFile(this.application_id, index, 'additional_documents', this.jwt_token)
      this.setUploadedAdditionalDocumentsReset({ index: index })
      this.refreshFileErrs()
    },

    triggerAdditionalFileInput(event, index) {
      event.preventDefault();
      jQuery("#additional_app_upload_" + index + ":hidden").trigger('click');
    },


    validateAdditionalFile(event, index, url) {
      let files = event.target.files;
      console.log("found files: ", files[0])
      if (files.length == 0) {
        return
      }
      console.log("url is: ", url)
      this.setUploadedAdditionalDocuments({ index: index, url: url, file: files[0] })
      console.log(" index value: ", index, "url is: ", this.uploaded_additional_documents.url)
      Validator.isValidUploadedFileWithCallback(files[0], this.validateAdditionalFileCallback, index);
    },

    async validateAdditionalFileCallback(valid, index, file) {
      if (!valid) {
        this.setUploadedAdditionalDocumentsReason({
          index: index,
          reason: 'could not be uploaded. Only the following extensions are allowed: PDF, JPG, JPEG, ZIP, TIFF, GIF, DOC, and DOCX.'
        })
      }
      let maxFileSize = 5000000
      let maxFileSizeText = '5MB'
      if (this.nonProd) {
        maxFileSize = 1000000
        maxFileSizeText = '1MB'
      }
      if (file.size > maxFileSize) {
        this.setUploadedAdditionalDocumentsReason({
          index: index,
          reason: 'exceeds  the maximum file size for this site. File sizes cannot be larger than ' + maxFileSizeText + '.'
        })

        valid = false;
      }

      // Attempt upload
      if (valid) {
        this.setUploadedAdditionalDocumentsUploadAttempt({

          index: index,
          value: true
        })

        this.setUploadedAdditionalDocumentsReason({

          index: index,
          reason: 'is uploading'
        })
        console.log("additional documents: ", this.uploaded_additional_documents[index], "file is: ", file)
        let resp = await Api.uploadFile(this.uploaded_additional_documents[index].url, file)

        // Did it get cancelled?
        if (!this.uploaded_additional_documents[index]) {
          return;
        }

        if (resp.status >= 300) {
          this.setUploadedAdditionalDocumentsUploadFailed({

            index: index,
            value: true
          })

          this.setUploadedAdditionalDocumentsReason({

            index: index,
            reason: 'could not be uploaded. There was an error during the upload process'
          })

          valid = false;
        }
      }
      if (valid) {
        this.setUploadedAdditionalDocumentsReason({

          index: index,
          reason: 'was successfully uploaded'
        })
        this.additional_file_count += 1
      }

      this.setUploadedAdditionalDocumentsUploadValid({

        index: index,
        value: valid
      })

      this.refreshAdditionalFileErrs()
    },
    refreshAdditionalFileErrs() {
      let additionalHasErr = false
      for (let m in this.uploaded_additional_documents) {
        if (this.uploaded_additional_documents[m] && !this.uploaded_additional_documents[m].valid) {
          additionalHasErr = true
          break
        }
      }
      this.errorAdditionalFileValidation = additionalHasErr
    },
    handleAdditionalDrop(e, index, url) {
      let dt = e.dataTransfer
      let files = dt.files

      if (files && files.length && files.length > 0) {
        this.validateAdditionalFileDD(e, index, url, files[0])
      }
    },
    validateAdditionalFileDD(event, index, url, file) {

      this.setUploadedAdditionalDocuments({ index: index, url: url, file: file })

      Validator.isValidUploadedFileWithCallback(file, this.validateAdditionalFileCallback, index);
    },
    gotoFHB() {
      // form validation
      window.location.replace('https://fhb.com');
    },
    writeFilenamesToStore() {
      // iterate an object
      const length = Object.keys(this.uploaded_documents).length
      let appFilenames = [];

      for (let i = 0; i < length; i++) {
        if (!this.uploaded_documents[i] || !Validator.isValidFile(this.uploaded_documents[i].file)) {
          continue
        }
        appFilenames.push(this.uploaded_documents[i].file.name);
      }

      this.setUploadedFiles(appFilenames);


      const length2 = Object.keys(this.uploaded_additional_documents).length
      let appFilenames2 = [];

      for (let i = 0; i < length2; i++) {
        if (!this.uploaded_additional_documents[i] || !Validator.isValidFile(this.uploaded_additional_documents[i].file)) {
          continue
        }
        appFilenames2.push(this.uploaded_additional_documents[i].file.name);
      }

      this.setUploadedAdditionalFiles(appFilenames2);

    },
  }
}
</script>


<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "./src/scss/_mixins.scss";

th,
td {
  text-align: left;
  padding-right: 15px;
  padding: 10px;
}

.loan-or-line {
  outline: 1px dotted $orange;
}

.ll-section {
  text-align: left;
}

.section-title-med {
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;

  @include media-breakpoint-up(sm) {
    font-size: 1.75rem;
  }
}

.ll-row-status .toggle-btn-container {
  @include media-breakpoint-up(md) {
    > :first-child {
      min-width: 172px;
    }

    > :nth-child(2) {
      min-width: 230px;
    }

    > :nth-child(3) {
      min-width: 270px;
    }
  }
}

.row1-container {
  @include media-breakpoint-up(sm) {
    column-gap: 1.5em;
    display: flex;
    flex-wrap: wrap;
    row-gap: 0.5em;
  }
}

.row2-container {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-template-columns: 1fr 1fr;
    text-align: left;
  }
}

.ll-consent label {
  align-items: center;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 4px;
  column-gap: 8px;
  display: flex;
  padding: 1em 1em 1em;
  width: flex; // ?

  span {
    display: block;
    flex: 1;
    font-weight: 700;
  }
}

.tf-label-banker {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 4px;
  margin-top: 28px;
  position: relative;

  @include media-breakpoint-up(sm) {
    font-size: 1.1rem;
  }
}

.ll-position {
  @include media-breakpoint-down(md) {
    flex: 1;
  }

  @include media-breakpoint-up(md) {}
}

.contact-container {
  background-color: $grayBg;
}

.continue-btn,
.continue-btn-disabled {
  align-items: center;
  border: none;
  border-radius: 30px;
  display: flex;
  height: 60px;
  justify-content: center;
  margin: auto;
  padding: 0.25em 4em;
  text-align: center;
  text-transform: uppercase;
}

.continue-btn-disabled {
  background: $grayVlight;
  color: $gray;

  &:hover,
  &:active,
  &:focus {
    cursor: not-allowed;
  }
}

.continue-btn {
  background: $yellowLight;

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.tf-label-normal {
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

.dd-icon {
  display: inline-block;
  margin-right: 0.25em;
  vertical-align: middle;
}

.dd-upload-icon {
  display: inline-block;
  vertical-align: top;
}

.dd-upload-icon:hover {
  cursor: pointer;
}

.dd-txt {
  display: inline-block;
  font-size: 1.1rem;
  line-height: 1.5;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.011em;
  text-align: left;
  vertical-align: middle;
}

.dd-r-txt {
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 27px;
  letter-spacing: -0.011em;
  padding-left: 20px;
}

.dd-r-fail-txt {
  display: inline-block;
  font-size: 18px;
  font-style: italic;
  font-weight: 100;
  line-height: 27px;
  letter-spacing: -0.011em;
  vertical-align: middle;
  text-align: right;
}

.dd-area {
  align-items: center;
  background: #FFFFFF;
  border: 1px dashed #B0B0B0;
  border-radius: 8px;
  column-gap: 1em;
  display: flex;
  width: 100%;
  min-height: 92px;
  text-align: left;
  padding: 30px;
}

.dd-upload-area {
  border: 1px solid $grayMid;
  border-radius: 8px;
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
}

.dd-upload-area-error {
  background-color: fade-out($yellow, 0.8);
  //border: 1px solid $yellow;
  border-left: 6px solid $yellow;
  border-radius: 0;
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
}

.dd-upload-area-error .dd-r-text,
.dd-upload-area-error .dd-reason {
  color: $black;
}

.dd-button {
  width: 140px;
  height: 40px;
  background-color: $white;
  border: 1px solid $gray;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  padding-left: 1.5em;
  padding-right: 1.5em;

  &:hover,
  &:focus,
  &:active {
    background-color: $grayLight;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.dd-button-success {
  width: 140px;
  height: 40px;
  background-color: $grayLight;
  border: 1px solid $grayLight;
  border: unset;
  border-radius: 20px;
  color: $grayDark;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 0.05em;
  padding-left: 1.5em;
  padding-right: 1.5em;

  .dd-icon.trash {
    height: 14px;
    width: auto;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $white;
    border: 1px solid $grayMid;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.dd-reason {
  font-style: italic;
  color: $grayDark;
}

.dd-area:hover {
  cursor: pointer;
}

#app_upload {
  display: none;
}

#coapp_upload {
  display: none;
}

.ll-emp-status .toggle-btn-container {
  display: grid;
  grid-column-gap: 1.5em;
  grid-row-gap: 1.5em;

  @include media-breakpoint-up(sm) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.ll-verification .toggle-btn-container {
  @include media-breakpoint-up(md) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-row-gap: 1.5em;
    grid-template-columns: 1fr 1fr;
  }
}

.ll-upload .toggle-btn-container {
  @include media-breakpoint-up(sm) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-row-gap: 1.5em;
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-up(lg) {
    display: grid;
    grid-column-gap: 1.5em;
    grid-row-gap: 1.5em;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.dd-icon {
  &.clip {
    height: auto;
    width: 28px;
  }

  &.trash {
    height: auto;
    width: 24px;
  }

  &.close {
    height: 12px;
    width: 12px;
  }
}

.dd-upload-icon {
  &.success {
    height: 24px;
    width: 24px;
  }

  &.load {
    height: 24px;
    width: 24px;
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
}

.upload-area {
  display: inline-flex;
  padding-right: 20px;
}

.button-area {
  align-self: center;
  margin-left: auto;

  button {
    align-items: center;
    column-gap: 4px;
    display: flex;
    font-weight: 700;
    justify-content: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
}

@-moz-keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.highlight {
  border: 2px dashed;
  border-color: $red;
}

.dd-button-clear {

  &:hover,
  &:active,
  &:focus {
    background-color: $white;
    border-color: $grayDark;
  }
}

.submit-btn,
.submit-btn-disabled {
  align-items: center;
  border: none;
  border-radius: 30px;
  display: flex;
  height: 60px;
  justify-content: center;
  margin: auto;
  padding: 0.25em 4em;
  text-align: center;
  text-transform: uppercase;
}

.submit-btn-disabled {
  background: $grayVlight;
  color: $gray;

  &:hover,
  &:active,
  &:focus {
    cursor: not-allowed;
  }
}

.submit-btn {
  background: $yellowLight;

  &:hover,
  &:active,
  &:focus {
    background-color: $yellowHover;
    cursor: pointer;
    transition: background-color 300ms ease, 300ms ease opacity;
  }
}

.submit-txt {
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 0.05em;
  text-align: center;
}

.link-container {
  margin-top: 2em;
}

.section-description {
  margin-bottom: 0;
}

.upload-docs-note {
  margin: 0;
}
.upload-docs-note + .upload-docs-note {
  margin-top: 1em;
}
</style>